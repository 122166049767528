import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import VisionCarousel from '../components/Vision Carousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}
const Services = () => {

useEffect(()=>{
document.title = "Gallery | Seama Women & Youth Empowerment Initiative (SWYEI)";

},[]);


  return (
    <>
   <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.6), rgba(8, 6, 4, 0.6)), url("./images/18.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'6rem 0',
    paddingTop:'10rem'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h2'>Gallery</h2>
            {/* <div className='pledge-line'></div> */}
         {/* <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p> */}
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Programmes</h2> */}
           <div className='content-container services-container'>
             <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Rubanda Soccer Academy supported by SWYEI</h3>
                    <p>Present are some of the directors.</p>
                     {/* <Link to='/education-skilling-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Rubanda Soccer Academy supported by SWYEI</h3>
                    <p>Here is a young group of young people deep in Rubanda District in western Uganda.  They are below the age of 14, girls and boys. In empowering these young people, they are now able to put their skills and energies to use. In this, they are able to avoid things like drugs and alcoholism as they are having constructive leisure.</p>
                     {/* <Link to='/education-skilling-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Rubanda Soccer Academy before our intervention</h3>
                        {/* <p>Gender Transformative Education seeks to utilize all parts of an education system to transform stereotypes, attitudes, norms, and practices by challenging power relations, rethinking gender norms, and raising critical consciousness about the root causes of inequality and discrimination. Educating girls to the same level as boys promotes growth and development in all aspects of life.</p>
                     <Link to='/gender-transformative-education-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>SWYEI's leadership pays a courtesy call to Rubanda District administration</h3>
                       
                       <p>
             On the right is Ampeire Stephen Kasyaba, Rubanda District LC5 Chairman and on the left is the Vice Chairman LC5 Rubanda district. 
                     
                     </p>
                      {/* <Link to='/community-mobile-skilling-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                     {/* <a href='/rental-services' className='home-btn'>Read More</a> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>
             <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'> A young girl fetching water at one of the areas in Rubanda District where clean and safe drinking water is needed </h3>
                        {/* <p>A young girl fetching water at one of the areas in Rubanda District where clean and safe drinking water is needed.</p> */}
                     {/* <Link to='/' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Rubanda Soccer Academy</h3>
                        {/* <p>Gender Transformative Education seeks to utilize all parts of an education system to transform stereotypes, attitudes, norms, and practices by challenging power relations, rethinking gender norms, and raising critical consciousness about the root causes of inequality and discrimination. Educating girls to the same level as boys promotes growth and development in all aspects of life.</p>
                     <Link to='/gender-transformative-education-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>

          
           </div>

    </section>
    </>
  )
}

export default Services