import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false} className='vision-slides'>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14.jpeg"
          alt="First slide"
        />    
      </Carousel.Item>
       {/* <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpeg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/16.jpeg"
          alt="First slide"
        />    
      </Carousel.Item> */}
            <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpeg"
          alt="First slide"
        />    
      </Carousel.Item>
       

    </Carousel>
  );
}

export default VisionCarousel;