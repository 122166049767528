import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | Seama Women & Youth Empowerment Initiative (SWYEI)";

},[]);


  return (
    <>
    
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.6), rgba(8, 6, 4, 0.6)), url("./images/2.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'6rem 0',
    paddingTop:'10rem'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h2'>About Us</h2>
            {/* <div className='pledge-line'></div> */}
         {/* <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p> */}
            
        </div>
    </section>

     
    <section className='section-cover who-we-are-cover' >
      {/* <h2 className="home-h2 wwa-h2">About Us</h2> */}
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>A society that empowers women and youth to be creative in order to be self-reliant.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p> To transform women and youth into self-reliant citizens aimed at improving psychosocial economic well-being in Uganda. </p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul className='core-values'>
<li>Transparency.</li>
<li>Empowerment.</li>
<li> Respect.</li>
<li>Diversity.</li> 
<li>Creativity.</li> 


                          </ul>
                          
                          

                    </article>

                    {/* <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article> */}


                 </div>
          </div>
    </section>
    {/* <section className="cert-cover">
      
                          
                           <div className='vm-line'></div>
    
        <div>
            <h3 className='title-h3 title-h3-c'>Governance Structure</h3>
          <img src='images/49.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/19.jpg' className='certification-image'></img>
        </div>

    </section> */}

    </>
  )
}

export default WhoWeAre