import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/1.jpeg"
          alt="First slide"
        />

      </Carousel.Item> */}
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14a.jpeg"
          alt="First slide"
        />    
      </Carousel.Item>
     
      
    </Carousel>
  );
}

export default RoadCarousel;