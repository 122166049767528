import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import VisionCarousel from '../components/Mission Carousel';
const Contact = () => {
 let [successMessage, setSuccessMessage] = useState(false);
        let [errorMessage, setErrorMessage] = useState(false);
        let [sendingMessage, setSendingMessage] = useState(false);
        let formInput1 = useRef(null);
        let formInput2 = useRef(null);
        let formInput3 = useRef(null);
        let formInput4 = useRef(null);
        let formInput5 = useRef(null);

let sendFunc =()=>{
  setSendingMessage(true);
    setSuccessMessage(false);
    setErrorMessage(false);
  
}


  useEffect(()=>{
document.title = 'Donate | Seama Women & Youth Empowerment Initiative (SWYEI)';

},[]);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fad3q6x', 'template_scrjxou', form.current, 'IsrVqrbvPIqfzUS9m')
      .then((result) => {
          // console.log(result.text);
          // console.log('Message Sent');
          if(result){
setSuccessMessage(true);
          setSendingMessage(false);

              setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

         }

      }, (error) => {
          // console.log(error.text);
           if (error){
setErrorMessage(true);
          setSendingMessage(false);
           setTimeout(() => {
           setErrorMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

          }
      });
  };
  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.5), rgba(8, 6, 4, 0.5)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'6rem 0',
    paddingTop:'10rem'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h2'>Donate</h2>
            {/* <div className='pledge-line'></div> */}
         <p> 
 We appreciate your interest in supporting us. Kindly fill out the form below and we'll get back to you as soon as possible.
</p>
            
        </div>
    </section>
    <section className='section-cover contact-cover' >
      {/* <h2 className="home-h2 ">Write To Us</h2> */}
         <div className='content-container contact-section'>
          {/* <p className='home-p home-p1'>Interested in our services, or do you have a business challenge? Get in touch with us and we shall add value. That is our promise!</p> */}

<div className='form-cover'>
  {/* <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Contact Number</label>
      <input type="number" name="user_number" />
      <label>Subject</label>
      <input type="text" name="user_subject" />


      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" ref={formInput1}/>
      <label>Email</label>
      <input type="email" name="user_email" ref={formInput2}/>
      <label>Contact Number</label>
      <input type="text" name="user_number" ref={formInput3}/>
      <label>Country</label>
      <input type="text" name="user_country" ref={formInput4} />
      <label>Amount</label>
      <input type="text" name="user_amount" ref={formInput5} />


      {/* <label>Message</label>
      <textarea name="message" ref={formInput5}/> */}
       <div  className={`${sendingMessage?'success-box':'hide'}`}>Sending...</div>
      <div  className={`${successMessage?'success-box':'hide'}`}>Thank you! Your message has been sent.</div>
      <div className={`${errorMessage?'error-box':'hide'}`}>Oh, sorry! An error occurred.</div>
      <input type="submit" value="Submit" onClick={sendFunc} />
    </form>
</div>
           <div className='contact-slides'> 
<VisionCarousel></VisionCarousel>
</div>

         </div>

    </section>
    </>
  )
}

export default Contact