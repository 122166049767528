import React, {useEffect} from 'react';
import CoreValues from '../components/CoreValues';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'
import {FaCalendarPlus,FaArrowAltCircleRight,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

const Services = () => {

useEffect(()=>{
document.title = 'Our Projects | St. Augustine Community Health';

},[]);


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Projects</h2>
          <section className='section-cover section-cover1 section-cover-a'>
                           
                            <h2 className='home-h2 home-service-h2 goals-h2'>St. Augustine Medical Centre - Mbarara</h2> 
                              <div className='line1'></div>
                              <h3 className='title-h3 title-h3-b'>Our clinic for excellency</h3>
       <div className='content-container goals-container goals-container-a goals-container-b'>
           <article className='goals-text'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>St. Augustine Medical Center</h2> */}
              {/* <div className='line1'></div> */}
              <div className='goals-list goals-list1'>
                        {/* <h3 className='title-h3'>Services</h3> */}
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}
                     <img src='./images/50.jpg' className='medical-center'></img>

                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>General Medical Services</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Comprehensive Laboratory Services</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Post Abortion Care</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Cervical Cancer Screening</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Male Medical Circumcision</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Antenatal Care & Deliveries</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Family Planning</p>
                     </div> */}
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Immunisation</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>HIV/AIDS Counselling & Testing</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>STD/STI Management</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Ultra Sound Scan</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Youth Friendly Services</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Couple Counselling</p>
                     </div> */}

{/* <Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link> */}
                </div>
                   

           </article>

            <article className='goals-text goals-text-a'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>St. Augustine Medical Center</h2> */}
              {/* <div className='line1'></div> */}
              <div className='goals-list goals-list1 goals-list1-a'>
                        {/* <h3 className='title-h3'>Services</h3> */}
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}
 <p className='home-p'><strong> Location:</strong> Ntare road, Opposite Ntare school main gate, Kamukuzi, Mbarara city.</p> 
<p className='home-p'><strong>Opening Hours:</strong>  Monday to Saturday 8:00 am to 8:00 pm for all services and 24 hours for emergencies and deliveries.</p>
<p className='home-p'>This is a fully fledged medical centre the first project under the leadership of our organization St. Augustine Community Health Centre (SACH). It’s a profit making which uses a user fee service pricing. It’s the sustainability point for the organization during periods of non-funding.</p>

                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>General Medical Services</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Comprehensive Laboratory Services</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Post Abortion Care</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Cervical Cancer Screening</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Male Medical Circumcision</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Antenatal Care & Deliveries</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Family Planning</p>
                     </div> */}
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Immunisation</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>HIV/AIDS Counselling & Testing</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>STD/STI Management</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Ultra Sound Scan</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Youth Friendly Services</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Couple Counselling</p>
                     </div> */}

{/* <Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link> */}
                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article> */}




       </div>

  </section>
  
    
          <div className='content-container vm-container'>
        
                    <article className='vision-mission-info vision-mission-info-a'>
{/* <h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div> */}
<p className='home-p'>Our focus is mainly reproductive health services working to build the first reproductive health hospital in Uganda. We offer the range of services which include the following;</p>
           <CoreValues></CoreValues>

                    </article>      
          </div>


    </section>
  )
}

export default Services