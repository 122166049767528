import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpg"
          // src="./images/24a.jpg"
          // src="./images/1a.jpg"
           src="./images/14.jpeg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Seama Women & Youth Empowerment Initiative<br></br> (SWYEI)</h3>
          {/* <p className="slide-p slide-p1">"Service with quality"</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/3.jpg"
          //  src="./images/40.jpg"
            src="./images/7.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p">Empowering the youth and women with Technical Skills</p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/6.jpg"
          // src="./images/37.jpg"
           src="./images/3.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
          Transforming women and youth into self-reliant citizens 
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/10.jpg"
          // src="./images/29a.jpg"
           src="./images/6.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
           {/* Contributing to social upliftment */}
           Promoting agriculture
          </p>
        </Carousel.Caption>

        
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.png"
          src="./images/4a.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>We Plan, Schedule, Execute & Control Projects
          </h3>
          <p className="slide-p">
           Equipping youth and women with hands-on skills for their everyday life
          </p>
        </Carousel.Caption>

        
      </Carousel.Item> */}
    </Carousel>
  );
}

export default CarouselFade;