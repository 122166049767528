import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Team from './pages/Team';
import Projects from './pages/Projects';
import Equipment from './pages/Equipment';
import Approach from './pages/Approach';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Gallery from './pages/NewGallery';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import Roads from './pages/Roads';
import Support from './pages/Support';
import Maintenance from './pages/Maintenance';
import Community from './pages/Community';
import Gender from './pages/Gender';
import Education from './pages/Education-Skilling';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";
import Programmes from "./pages/Programmes";
import Donate from "./pages/Donate";


function App() {
  return (
    <BrowserRouter>
    {/* <TopSection></TopSection> */}
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='our-team' element={<Team></Team>}></Route>
            <Route path='programmes' element={<Programmes></Programmes>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='pictorial' element={<Gallery></Gallery>}></Route>
             <Route path='gallery' element={<Gallery></Gallery>}></Route>
             
            <Route path='certifications' element={<Certification></Certification>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='power' element={<Power></Power>}></Route>
 <Route path='road-construction' element={<Roads></Roads>}></Route>
 <Route path='projects' element={<Support></Support>}></Route>
  <Route path='support' element={<Support></Support>}></Route>
  <Route path='maintenance' element={<Maintenance></Maintenance>}></Route>
<Route path='community-mobile-skilling-projects' element={<Community></Community>}></Route>
  <Route path='gender-transformative-education-projects' element={<Gender></Gender>}></Route> 
   <Route path='education-skilling-projects' element={<Education></Education>}></Route>
   <Route path='approach' element={<Approach></Approach>}></Route> 
   <Route path='donate' element={<Donate></Donate>}></Route>           
             {/* Footer Services Links */}

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
