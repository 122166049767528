import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
    

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  )
}
export default Tool1