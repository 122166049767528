import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='tools-slides'>
    

      <Carousel.Item>
        <img
          className="d-block w-100"
          //  src="./images/1.jpg"
          src="./images/17.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2.jpeg"
          alt="First slide"
        />
      </Carousel.Item> */}

    </Carousel>
  )
}
export default Tool1