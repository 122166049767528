import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | Given Logistics Ltd';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
          {/* <p className='clients-para'></p> */}
        <div className='content-container clients'>

           

            <article className='client'>
                      <img src='./images/c1.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/c2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/c3.jpg' alt='client' className='client-image'></img>
                      
            </article>

            <article className='client'>
                      <img src='./images/c4.png' alt='client' className='client-image'></img>
                        
            </article>

            <article className='client'>
                      <img src='./images/c5.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c6.jpg' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/c7.jpeg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c8.png' alt='client' className='client-image'></img>            
            </article>
           

            <article className='client'>
                      <img src='./images/c9.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c10.jpg' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/c10.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c11.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c12.png' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/c13.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/c14.png' alt='client' className='client-image'></img>            
            </article>

        

            <article className='client'>
                      
                      <img src='./images/c14.png' alt='client' className='client-image'></img>
             <p><strong>Entebbe Municipal Council <br></br>Katabi Health Center III</strong></p>
           </article>

           <article className='client'>
                      
                      <img src='./images/c14.png' alt='client' className='client-image'></img>
             <p><strong>Entebbe Grade B Regional Referral Hospital</strong></p>
           </article>
          
          
          
          </div> 
    </section>
  )
}

export default Clients